<template>
  <div class="agreement">
    <div class="content">
      <h1>医百云服务协议</h1>

      <p class="time">最近更新时间：2021-02-10 12:00</p>

      <p>欢迎您使用医百云服务！</p>

      <p>
        本协议是医百云与您，就您使用医百云服务的相关事项签订的合同。为使用医百云服务，您应当充分阅读、理解本协议，其中限制、免责条款或者其他涉及您重大权益的条款（如违约处罚、争议管辖等）可能会以加粗、加下划线等形式提示您重点注意。
      </p>

      <p>
        除非您已充分阅读、理解并同意接受和遵守本协议，否则，请您不要使用医百云服务。您通过网页确认或以其他任何方式明示或者默示表示接受本协议，或您以任何方式使用医百云服务的，即视为您已阅读并同意接受本协议的约束，本协议即在您与医百云之间产生法律效力。
      </p>

      <p>
        使用医百云服务应当遵守本协议及其服务规则。服务规则以医百云官网或其他相关页面展示的内容为准，您应事先了解服务规则，并根据服务规则进行操作，以确保顺利使用。
      </p>

      <h4>第一条 通则</h4>
      <p>
        1.1
        医百云服务指医百云提供的即时通迅IM、用户服务、短链接服务、评论服务、短信服务、抽奖服务、投票服务、在线考试服务与表单服务等各种产品、服务（具体以医百云实际提供的为准），您可以根据自己的需求选择使用一项或多项具体服务并遵守其服务规则。
      </p>

      <p>
        1.2
        涉及具体服务的服务内容、服务等级、技术规范、操作文档、计费标准等内容的服务条款、规则、说明、标准等（统称为“服务规则”），以医百云官网或其他相关页面展示的内容为准。
      </p>

      <p>
        1.3
        医百云是中立的技术服务提供者，依约向您提供各类技术产品和服务；您的网站、应用、软件、平台等任何产品、服务及相关内容等，由您自行运营并承担全部责任。
      </p>

      <p>
        1.4
        双方均保证已经依照国家相关规定获得了合法经营资质或政府审批等，有权依法运营其产品及服务。双方进一步保证，在本协议有效期内持续保持具备国家相关规定要求的经营资质或审批手续。
      </p>

      <h4>第二条 账号</h4>
      <p>
        2.1
        您应依法具备必要、适当的权利能力和行为能力，按照医百云的要求完成注册、获得医百云服务账号（以下简称“账号”）。
      </p>

      <p>
        2.2
        为保护账号的安全性和独立性，避免出现账号归属不清晰等不良后果，您应当使用您享有合法权益的手机号等进行注册或登录，否则，可能导致您无法正常登录和使用医百云服务。
      </p>

      <p>
        2.3
        您应当按照医百云服务流程填写、提交真实、合法、有效的资料（统称“客户资料”），包括但不限于名称、联系人、电子邮箱、联系电话、联系地址、工商登记证件等；如果客户资料发生变更的，您应及时书面通知医百云或根据医百云的规则自行进行更新。
      </p>

      <p>
        2.4
        本协议效力适用于您名下全部账号。账号将作为您使用医百云服务的身份识别依据，您应当对用户名、密码等信息采取必要、有效的保密和安全保护措施（包括但不限于：保管使用权限、设置高强度密码和定期更换措施等），否则，导致的后果由您自行承担。此外，您应为账号下的行为负责，所有账号下的操作行为均被视为您实施，且应由您承担全部法律后果。
      </p>

      <p>
        2.5
        您需自行建立健全内部管理制度，规范对账号的使用与管理。为保障您的权益，账号应仅供您自行使用，若您基于自身经营需要等原因，将账号授权您的员工或他人管理的，须做好权限管控，并且在遇到人员变动时及时完成交接和账号安全保护（例如修改密码、变更登录方式、设置账号保护等）。
      </p>

      <p>
        2.6
        为保护账号和业务等的安全，您应依照国家相关规定及医百云流程、制度进行实名认证。您同意授权医百云通过第三方核实您提交的相关信息或资料。
      </p>

      <p>
        2.7
        实名认证是对账号归属以及责任承担等进行判断的依据，在发生账号归属争议等纠纷时，医百云有权认定该账号归属于实名认证主体；对于归属存在争议的账号，医百云有权暂时对该账号进行冻结。您在进行认证时务必谨慎对待，给与足够的重视并确保认证主体与账号使用主体保持一致，避免将企业使用的账号认证在个人名下，或将本人使用的账号认证在他人名下等任何不当认证行为。
      </p>

      <p>
        2.8
        若您发现有他人盗用您的账号及密码、或任何其他未经您合法授权的情形时，您应立即以有效方式通知医百云并提供必要资料（如客户资料、情况说明、证明材料及诉求等，以便医百云核实身份及事件）。医百云收到您的有效通知并核实身份后，会依据法律法规及服务规则进行处理。医百云依据本条进行处理产生的相关责任和后果由您承担。
        若您提供的资料存在瑕疵，导致医百云无法核实您的身份或无法判断您的需求等，而导致医百云未能及时处理，给您带来的损失，应由您自行承担。同时，您理解，医百云对您的请求进行处理需要合理期限，对于医百云采取措施前您已经产生的损失以及采取措施后因不可归责于医百云的原因导致的损失，医百云不承担任何责任。
      </p>

      <h4>第三条 服务</h4>
      <p>3.1 服务开通</p>

      <p>
        3.1.1
        您可根据自身需求，自行通过医百云官网在线申请所需服务，申请前您需仔细阅读所选服务对应的服务规则并根据自身需求决定是否申请服务或使用。双方也可根据实际合作需要，另行签订协议等。
      </p>

      <p>
        3.1.2
        部分医百云服务的开通可能需要双方另行签订单独的服务协议，单独的服务协议可能以电子文档形式展示，也可能是独立的纸质文档形式展示，您可以根据自身情况决定是否接受服务协议及开通服务。
      </p>

      <p>3.2 服务支持</p>

      <p>
        3.2.1
        医百云向您提供售后服务，协助您解答、处理使用医百云服务过程中遇到的问题。
      </p>

      <p>
        3.2.2
        医百云将依照法律法规要求对其系统、设备等采取基础的安全保护措施。若您对安全保护措施的要求高于前述基础的安全保护措施标准的，您有权根据自身需求购买配置更高的安全保护服务或另行配置其他安全防护软件、系统等。
      </p>

      <p>
        3.2.3
        您应自行对您使用的计算机信息系统、设备等采取必要的、有效的安全保护措施，因您未采取前述措施导致权益受损的，由您自行承担责任。
      </p>

      <p>
        3.2.4
        医百云在服务规则约定内提供可用性保障。如果您对可用性的要求高于服务规则，则需要您主动对自身系统进行高可用性的设置，医百云可给与必要的协助。如果需要医百云配合做设计，由双方另行协商确认。
      </p>

      <p>3.3 服务中止或终止</p>

      <p>
        3.3.1
        为了向您提供更加完善的服务，医百云有权定期或不定期地对服务平台或相关设备、系统、软件等进行检修、维护、升级及优化等（统称“常规维护”），如因常规维护造成医百云服务在合理时间内中断或暂停的，医百云无需为此向您承担责任。但是，医百云应当至少提前24小时，就常规维护事宜通知您。若因不可抗力、基础运营商过错等原因导致的非常规维护，医百云应及时通知您。
      </p>

      <p>
        3.3.2
        为保证服务的安全性和稳定性，医百云可能进行机房迁移、设备更换等重大调整，前述情况可能导致医百云服务在合理时间内中断或暂停，医百云无需为此向您承担责任，但是，医百云应提前30天通知您，您应予以配合；如您不配合进行调整，或者医百云无法与您取得联系，由此产生的后果由您自行承担。
      </p>

      <p>
        3.3.3
        如您购买的具体服务含有存储功能的，在该服务到期或终止后，对于您存储在该服务中的数据等任何信息，医百云将根据该服务的服务规则为您保留相应期限。您应承担保留期限内产生的费用（若有），并按时结清费用、完成全部数据的迁移。保留期限届满后，您的前述信息将被删除。
      </p>

      <p>
        3.3.4
        医百云有权根据自身运营安排，随时调整、终止部分或全部服务（包括但不限于对服务进行下线、迭代、整合等）。但是，医百云应提前至少30天通知您，以便您做好相关数据的转移备份以及业务调整等，以保护您的合法权益。
      </p>

      <p>
        3.4
        基于网络服务的及时性、复杂性、高效性等特性及监管要求、政策调整等原因，您同意医百云可以不时对本协议以及医百云的相关服务规则进行调整，并通过网站公告、邮件通知、短信通知、系统消息、站内信等方式中的一种或多种予以公布；若您在调整后继续使用医百云服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的内容。
      </p>

      <p>
        3.5
        若您选择使用中国大陆以外的服务，应当确保您符合中国大陆法律法规、政策等的要求，同时，也应当确保您的资质、能力以及使用行为等均符合当地法律法规、政策等的要求。
      </p>

      <p>3.6 第三方产品或服务</p>

      <p>
        3.6.1
        如果您通过医百云平台获取、使用第三方提供的任何产品或服务（包括但不限于医百云服务市场中由服务商等第三方提供的产品、服务），您应当自行评估该产品或服务是否符合您要求。
      </p>

      <p>
        3.6.2
        第三方产品或服务的开通，可能需要您与第三方另行签订单独的协议，单独的协议可能以电子文档形式展示，也可能是独立的纸质文档，您可以根据自身情况决定是否接受协议及使用产品或服务。
      </p>

      <p>3.6.3 您因使用第三方产品或服务产生的纠纷由您与第三方自行解决。</p>

      <h4>第四条 客户权利义务</h4>
      <p>
        4.1 您有权依照本协议约定使用医百云服务并获得医百云的技术支持和售后服务。
      </p>

      <p>
        4.2
        您在使用医百云平台上的服务时须遵守相关法律法规及服务规则，并确保拥有法律法规要求的经营资质和能力，不得实施包括但不限于以下行为，也不得为任何违反法律法规的行为提供便利：
      </p>

      <p>4.2.1 反对宪法所规定的基本原则的。</p>

      <p>4.2.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。</p>

      <p>4.2.3 损害国家荣誉和利益的。</p>

      <p>4.2.4 煽动民族仇恨、民族歧视，破坏民族团结的。</p>

      <p>4.2.5 破坏国家宗教政策，宣扬邪教和封建迷信的。</p>

      <p>4.2.6 散布谣言，扰乱社会秩序，破坏社会稳定的。</p>

      <p>4.2.7 散布淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的。</p>

      <p>4.2.8 侮辱或诽谤他人，侵害他人合法权益的。</p>

      <p>4.2.9 实施任何违背“七条底线”的行为。</p>

      <p>4.2.10 含有法律、行政法规禁止的其他内容的。</p>

      <p>
        4.3
        您在使用医百云平台上的服务时须维护互联网秩序和安全，不得侵犯任何主体的合法权益，不得实施包括但不限于以下行为，也不得为其提供便利：
      </p>

      <p>
        4.3.1
        实施诈欺、虚伪不实或误导行为，或实施侵害他人知识产权等任何合法权益的行为。
      </p>

      <p>
        4.3.2
        发布、传播垃圾邮件（SPAM）或包含危害国家秩序和安全、封建迷信、淫秽、色情、低俗等违法违规信息。
      </p>

      <p>
        4.3.3
        违反与医百云网络相联通之网络、设备或服务的操作规定；实施违法或未授权之接取、盗用、干扰或监测。
      </p>

      <p>
        4.3.4
        实施任何破坏或试图破坏网络安全的行为，包括但不限于以病毒、木马、恶意代码、钓鱼等方式，对网站、服务器进行恶意扫描、非法侵入系统、非法获取数据等。
      </p>

      <p>
        4.3.5
        实施任何改变或试图改变医百云服务提供的系统配置或破坏系统安全的行为；利用技术或其他手段破坏、扰乱医百云服务的运营或他人对医百云服务的使用；以任何方式干扰或企图干扰医百云任何产品或任何部分、功能的正常运行，或者制作、发布、传播上述工具、方法等。
      </p>

      <p>
        4.3.6 因从事包括但不限于“DNS
        解析”、“安全服务”、“域名代理”、“反向代理”等任何业务，导致您自己频繁遭受攻击（包括但不限于
        DDoS
        攻击）且未及时更正行为，或未根据医百云要求消除影响，从而对医百云服务平台或他人产生影响的。
      </p>

      <p>4.3.7 实施其他破坏互联网秩序和安全的行为。</p>

      <h4>第五条 医百云权利义务</h4>
      <p>5.1 医百云应当依照本协议约定向您提供医百云服务和售后支持。</p>

      <p>
        5.2
        医百云仅对医百云服务本身提供运营维护，您应当保证自身的网络、设备的安全性、稳定性，如发生以下情况，您应及时解决并避免对医百云服务产生影响：
      </p>

      <p>5.2.1 您内部网络出现问题，包括但不限于超负荷等。</p>

      <p>5.2.2 您自有设备或您使用的第三方设备出现故障。</p>

      <p>5.2.3 您自行拆离设备或通过其他方式导致网络中断。</p>

      <p>5.2.4 其他您自身原因导致的任何故障、网络中断等。</p>

      <p>
        5.3
        若医百云自行发现或根据相关部门的信息、权利人的投诉等发现您可能存在违反相关法律法规或本协议的行为的，医百云有权根据一般人的认识自行独立判断，并随时单方采取以下措施中的一种或多种：
      </p>

      <p>5.3.1 要求您立即删除、修改相关内容。</p>

      <p>
        5.3.2
        限制、暂停向您提供全部或部分医百云服务（包括但不限于直接对您的部分服务进行下线并收回相关资源、对您账号采取操作限制/账号冻结等措施）。
      </p>

      <p>
        5.3.3
        终止向您提供医百云服务，终止协议（包括但不限于直接对您的全部服务进行下线并收回相关资源等）。
      </p>

      <p>
        5.3.4
        医百云根据本协议终止向您提供服务或终止协议的，您预缴的费用将作为违约金归医百云所有。
      </p>

      <p>
        5.3.5 依法追究您的其他责任。
        医百云依据本协议采取相应措施（包括但不限于暂停服务、扣除费用、终止协议等），不视为医百云违约，若因此给您造成损失（包括但不限于业务暂停、数据清空等）的，均由您自行承担。因您违反本协议约定产生的责任和后果由您自行承担，若给医百云或第三方造成损失的，您应当予以赔偿。
      </p>

      <p>
        5.4
        为合理保护您、您的用户及权利人等各方的利益，医百云有权制定专门的侵权、投诉流程制度，您应当予以遵守。如果医百云接到第三方对您的投诉或举报，医百云有权向第三方披露您相关资料（如您的主体资料及您就投诉或举报提交的反通知书、相关证据等全部资料），要求您与投诉方进行协商（包括但不限于建立包含您、医百云以及投诉方的三方邮件组，供您、投诉方直接沟通、相互举证等，下同），若您投诉或举报其他医百云客户的，医百云也有权向被投诉方披露您相关资料（如您的主体资料及您就投诉或举报提交的通知书、相关证据等全部资料），要求您与被投诉方进行协商，以便及时解决投诉、纠纷，保护各方合法权益，您应当予以配合，否则，可能会影响您继续使用医百云服务，由此造成您或他方损失的，您自行承担。
      </p>

      <p>
        5.5
        医百云可将本协议部分或全部权利义务转让予第三方，但医百云须提前90天书面通知您。为本款生效之需要，双方同意签订所有合理必要的文件及采取合理必要的措施。
      </p>

      <p>
        5.6
        您同意医百云有权使用您的名称、品牌、商标标识等作为商业案例进行宣传、展示。
      </p>

      <h4>第六条 客户数据</h4>
      <p>
        6.1
        您保证，您存储、上传到医百云服务中，或者利用医百云服务以分析、分发等任何方式处理的数据，为您依法收集、获取或产生的数据，您不会也不曾以任何方式侵犯任何个人或实体的合法权利。
      </p>

      <p>
        6.2
        您保证其有权使用医百云服务对前述数据进行存储、上传、分析和分发等任何处理，且前述处理活动均符合相关法律法规的要求，不存在任何违法、侵权或违反其与第三方的合同约定的情形，不会将数据用于违法违规目的。
      </p>

      <p>
        6.3
        您有权自行使用医百云服务对数据进行上传、分析、删除、更改等处理（具体以您使用的服务的服务规则为准），就数据的删除、更改等处理，您应谨慎操作并承担因该等操作所产生的后果。
      </p>

      <p>
        6.4
        除因您使用医百云服务所需及法律法规另有规定外，未经您同意，医百云不会接触或使用您的数据。
      </p>

      <p>
        6.5
        您应根据自身需求自行对数据进行备份，医百云仅依据相关法律法规要求或服务规则约定提供数据备份服务，医百云仅在法定或约定提供的数据备份服务的范围内承担责任。
      </p>

      <p>
        6.6
        因您的数据的产生、收集、处理、使用等任何相关事项存在违反法律法规等情况而造成的全部结果及责任均由您自行承担，并且，医百云有权随时全部或部分终止向您提供医百云服务。您因此遭受的一切损失或承担的一切责任和费用，由您自行承担。
      </p>

      <h4>第七条 知识产权</h4>
      <p>
        7.1
        双方各自享有的商标权、著作权或者其他知识产权，均仍归各方单独享有，并不会因为双方签订或者履行本协议而转归对方享有，或者转归双方共同享有。
      </p>

      <p>
        7.2
        任何一方均应尊重相对方、第三方的知识产权，如有任何第三方就一方侵犯其知识产权等合法权益的原因向另一方提起诉讼、主张索赔的，责任方应当独立处理相关纠纷，赔偿另一方的直接经济损失，并使另一方免责。
      </p>

      <h4>第八条 保密信息</h4>
      <p>
        8.1
        双方为了本协议目的，已经或将会提供或透露某些保密信息。其中，披露保密信息的一方为“披露方”，而接受保密信息的一方为“接收方”。
      </p>

      <p>
        8.2
        “保密信息”指由披露方持有的与其业务、经营、技术及权利等事项相关的，非公开的信息、资讯、数据、资料等，包括但不限于营业规划，商务资料，与技术有关之知识及信息、创意、设想、方案，提供的物品或厂商资料，用户信息，人事资料，商业计划，服务价格及折扣，财务状况等。
      </p>

      <p>
        8.3
        接收方从披露方所获悉的信息，如有标明或可合理认知为属于披露方的保密信息的，接收方须对披露方的保密信息保密，未经披露方书面同意，不得以任何方式披露给第三方或用于本协议以外目的。接收方应以对待其自身保密信息相同的注意程度（且不得低于合理审慎义务）对待披露方的保密信息。
      </p>

      <p>8.4 尽管有前述约定，符合下列情形之一的，相关信息不被视为保密信息：</p>

      <p>
        8.4.1 接收方在披露方向其披露之前已经通过合法的渠道或方式持有的信息。
      </p>

      <p>
        8.4.2
        该信息已经属于公知领域，或该信息在非因接收方违反本协议的情况下而被公开。
      </p>

      <p>
        8.4.3 接收方合法自其他有权披露资料的第三方处知悉且不负有保密义务的信息。
      </p>

      <p>
        8.4.4 由接收方不使用或不参考任何披露方的保密信息而独立获得或开发的。
      </p>

      <p>
        8.5
        如果接收方基于法律法规或监管机关的要求，需要依法披露披露方的保密信息的，不视为违反本协议，但接收方应当在法律许可的范围内尽快通知披露方，同时，接收方应当努力帮助披露方有效限制该保密信息的披露范围，保护披露方合法权益。
      </p>

      <p>
        8.6
        双方保密义务在本协议有效期限内及期限届满后持续有效，直至相关信息不再具有保密意义。
      </p>

      <p>
        8.7
        一旦发生保密信息泄露事件，双方应合作采取一切合理措施避免或者减轻损害后果的产生；如因接收方违反保密义务给披露方造成损失的，接收方应赔偿因此给披露方造成的直接经济损失。
      </p>

      <p>8.8 医百云会按照公布的《医百云隐私声明》的规定保护您的相关信息。</p>

      <h4>第九条 出口管制与制裁法律合规</h4>
      <p>
        各方承诺遵守所有适用的经济与贸易制裁以及出口管制法律法规，包括所有由联合国安全理事会、中国、美国及任何其他国家所制定并执行的制裁决议、法律与法规以及出口管制法律与法规（在前述文件适用于该方的范围内）（下称“适用出口管制法律”）。您承诺不会将医百云提供的产品或服务用于适用出口管制法律所禁止的用途。非经相关主管机关许可，您及您授权使用医百云提供的产品或服务的个人或实体不会通过医百云提供的产品或服务向所适用出口管制法律所制裁或指定的个人或实体提供受控的技术、软件或服务，或以任何方式使得医百云违反适用出口管制法律。
      </p>

      <h4>第十条 责任限制</h4>
      <p>
        10.1
        您理解并同意，在使用医百云服务的过程中可能会遇到以下情况使服务发生中断。出现下述情况时，医百云应及时与相关单位配合进行修复，但是由此给您造成的损失医百云将予以免责。
      </p>

      <p>
        10.1.1
        不可抗力，包括但不限于自然灾害、政府行为、法律法规颁布调整、罢工（任一方内部劳资纠纷除外）、动乱等不能预见、不能避免并不能克服的客观情况。
      </p>

      <p>
        10.1.2
        基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进行安装、改造、维护。
      </p>

      <p>
        10.1.3 网络安全事故，如计算机病毒、木马或其他恶意程序、黑客攻击的破坏。
      </p>

      <p>
        10.1.4
        您通过非医百云授权的方式使用医百云服务，您操作不当或您的电脑软件、系统、硬件和通信线路出现故障。
      </p>

      <p>10.1.5 其他非医百云过错、医百云无法控制或合理预见的情形。</p>

      <p>
        10.2
        因不可抗力、基础运营商原因、网络安全事故或其他超出当事人可合理掌控范围的事件，造成本协议迟延履行或任何一方违约，双方均无需承担违约责任。但是，受影响一方应尽可能及时通知另一方。如前述事件妨碍协议履行达30天以上的，任一方可提前15天书面通知对方终止协议。因本条款终止协议的，任何一方均无须承担违约责任。
      </p>

      <p>
        10.3
        您理解并同意，医百云的服务是按照现有技术和条件所能达到的现状提供的。医百云将尽最大努力确保服务的连贯性和安全性，但医百云不能保证其所提供的服务毫无瑕疵，因此，即使医百云提供的服务存在瑕疵，但若上述瑕疵是当时行业技术水平所无法避免的，其将不视为医百云违约，医百云也无需承担任何责任，双方应友好协作共同解决问题。
      </p>

      <p>
        10.4
        于任何情形下，任一方均不对另一方之任何间接的、偶然的、特殊的或惩罚性的损害和损失（如利润损失、机会损失、向第三方支付的费用、声誉/商誉损失或损害等）承担责任，无论基于合同、保证、侵权或任何其他责任理论，不论任一方是否知道或应当知道上述损失或损害的可能性。
      </p>

      <p>
        10.5
        于任何情形下，医百云基于本协议及相关订单和医百云服务向您所承担的赔偿责任（无论基于合同、侵权或任何其他责任理论）的总额，不超过您就造成您损失的医百云服务已经向医百云支付的费用总额；如果造成您损失的相应医百云服务的服务期限超过12个月的，则医百云的最大赔偿责任不超过损害发生前12个月，您就造成您损失的医百云服务已经向医百云支付的费用总额（为免歧义，本处费用指您就实际使用该服务的时长/数量等而实际已支付的现金，不包括代金券、预付但未实际消耗的费用等）。若本条款与双方其他约定或医百云服务规则冲突或不一致的，以本条约定为准。
      </p>

      <h4>第十一条 通知和送达</h4>
      <p>
        11.1
        您应当保证和维持客户资料（包括但不限于电话号码、电子邮箱等联系方式）的有效性，客户资料若存在虚假、无效等任何可能导致您无法及时获悉业务通知、服务提示、客户服务、技术支持、纠纷协调、违规处罚等信息的，由您自行承担相应责任。
      </p>

      <p>
        11.2
        您应当根据医百云官网公布的医百云的联系方式向医百云发送通知，双方另有约定除外。
      </p>

      <p>
        11.3
        医百云可通过网页公告、系统通知、站内信、电子邮件、手机短信、即时通讯工具、函件等方式中的一种或多种向您发送与医百云服务有关的业务通知、服务提示、验证消息、营销信息等各种信息（包括但不限于更新后的服务规则、服务升级、机房裁撤、广告等）。前述信息在以下情况下视为已送达：
      </p>

      <p>11.3.1 交专人递送的，在收件人签收时视为已送达。</p>

      <p>11.3.2 以网页公告等形式公布的，一经公布即生效（另有说明除外）。</p>

      <p>
        11.3.3
        以电子形式（包括系统通知、站内信、电子邮件、手机短信、即时通讯工具等）发送的，在发送成功后视为已送达。
      </p>

      <p>
        11.3.4
        以邮资预付的快递公司递送或以挂号信递送的，以投邮后的第3个自然日视为已送达。
        如果送达时间为法定节假日的，则以送达后首个工作日为送达日。
      </p>

      <h4>第十二条 法律适用及争议解决</h4>
      <p>12.1 本协议的签订地为北京市朝阳区。</p>

      <p>
        12.2
        本协议的成立、生效、履行、解释及纠纷解决等，均适用中国大陆法律法规（不包括冲突法）。
      </p>

      <p>
        12.3
        因本协议产生之争议，双方应首先协商解决；双方未能协商解决的，任何一方均应将争议提交本协议签订地有管辖权的人民法院裁决。双方无争议的部分，应当继续履行。
      </p>

      <h4>第十三条 其他</h4>
      <p>
        13.1
        除非另有说明，本协议正文及其附件中所有的“天”均为自然日，结算货币均为人民币。
      </p>

      <p>
        13.2
        本协议的拟定、解释均以中文为准。除双方另有约定外，任何有关本协议的翻译不得作为解释本协议或判定双方当事人意图之依据。
      </p>

      <p>
        13.3
        服务规则为本协议的重要组成部分，与本协议正文具有同等法律效力，您应当予以遵守。
      </p>
      <div class="button">
        <van-button size="large" @click="back" class="btn" type="default">{{
          $t("common.back")
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
@dialog-message-font-size: 60px;
.agreement {
  width: 100%;
  height: 100%;
  font-size: 28px;
  color: #333;
  padding: 32px;
  .content{
      padding-bottom: 100px;
  }
  .time {
    color: #999;
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
  }
}
</style>
